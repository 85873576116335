export default [
  {
    // 账号管理列表页
    path: 'accountManager',
    name: 'accountManager',
    meta: {
      permissionCode: 'cp-accountManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/account-manager/account-manage.vue'),
  },
  {
    // 新增账号
    path: 'accountManager/creatStaff',
    name: 'creatStaff',
    meta: {
      permissionCode: 'cp-accountManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/account-manager/creat-staff.vue'),
  },
  {
    // 角色管理列表页
    path: 'departmentManager',
    name: 'departmentManager',
    meta: {
      permissionCode: 'cp-accountManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/account-manager/department-manager.vue'),
  },
  {
    // 角色编辑
    path: 'departmentManager/editRole',
    name: 'hotel-editRole',
    meta: {
      permissionCode: 'cp-accountManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/account-manager/edit-role.vue'),
  },
  {
    // 操作历史
    path: 'operationHistory',
    name: 'operationHistory',
    meta: {
      permissionCode: 'cp-accountManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/account-manager/operation-history.vue'),
  },
  {
    // CRS操作日志
    path: 'crsOperateLogHotel',
    name: 'crsOperateLogHotel',
    meta: {
      permissionCode: 'cp-data-monitor',
      routerType: 3,
    },
    component: () => import('../views/group-management/data-monitoring/crs-operate-log/index.vue'),
  },
];
