export default [
  {
    // 子集团管理
    path: 'subGroupManagent',
    name: 'subGroupManagent',
    meta: {
      permissionCode: 'cp-group-subGroupManagent',
      routerType: 2,
    },
    component: () => import('../views/group-management/subgroup-management/index.vue'),
  },
  {
    // 集团信息维护-基本信息配置
    path: 'groupBaseInfoManagement',
    name: 'groupBaseInfoManagement',
    meta: {
      permissionCode: 'cp-group-groupInfoManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-information-management/group-base-infomation.vue'),
  },
  {
    // 集团信息维护-财务信息配置
    path: 'balanceAccount',
    name: 'balanceAccount',
    meta: {
      permissionCode: 'cp-group-groupInfoManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-information-management/balance-account.vue'),
  },
  {
    // 集团账号管理-列表页
    path: 'groupAccountList',
    name: 'groupAccountList',
    meta: {
      permissionCode: 'cp-accountManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/account-manager/account-manage.vue'),
  },
  {
    // 集团账号管理-新增员工
    path: 'groupAccountList/groupCreateStaff',
    name: 'groupCreateStaff',
    meta: {
      permissionCode: 'cp-accountManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/account-manager/children/group-creat-staff.vue'),
  },
  {
    // 角色管理列表
    path: 'groupDepartmentManage',
    name: 'group-departmentManage',
    meta: {
      permissionCode: 'cp-accountManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/account-manager/department-manager.vue'),
  },
  {
    // 角色配置
    path: 'groupDepartmentManage/groupEditRole',
    name: 'groupEditRole',
    meta: {
      permissionCode: 'cp-accountManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/account-manager/edit-role.vue'),
  },
  {
    // 操作历史
    path: 'groupOperationHistory',
    name: 'group-operationHistory',
    meta: {
      permissionCode: 'cp-accountManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/account-manager/operation-history.vue'),
  },
  {
    // CRS操作日志
    path: 'crsOperateLog',
    name: 'crsOperateLog',
    meta: {
      permissionCode: 'cp-data-monitor',
      routerType: 2,
    },
    component: () => import('../views/group-management/data-monitoring/crs-operate-log/index.vue'),
  },
  {
    // 渠道操作日志
    path: 'channelLog',
    name: 'channelLog',
    meta: {
      permissionCode: 'cp-data-monitor',
      routerType: 2,
    },
    component: () => import('../views/group-management/data-monitoring/channel-log/index.vue'),
  },
];
