export default [
  {
    // 集团列表
    path: 'hotelIndex',
    name: 'hotelIndex',
    meta: {
      permissionCode: 'hotelIndex',
      routerType: 1,
    },
    component: () => import('../views/platform/hotel-index/index.vue'),
  },
  {
    // 集团管理列表
    path: 'groupmanage',
    name: 'grouplist',
    meta: {
      permissionCode: 'groupmanage',
      routerType: 1,
    },
    component: () => import('../views/platform/group-manage/list/index.vue'),
  },
  {
    // 集团信息编辑
    path: 'groupmanage/info/:groupid?/:unitid?',
    name: 'groupinfo',
    meta: {
      permissionCode: 'groupmanage',
      routerType: 1,
    },
    component: () => import('../views/platform/group-manage/info/index.vue'),
  },
  {
    // 权限管理
    path: 'permissionsmanage',
    name: 'permissionslist',
    meta: {
      permissionCode: 'permissionsmanage',
      routerType: 1,
    },
    component: () => import('../views/platform/permissions-manage/list/index.vue'),
  },
];
