export default [
  {
    // 集团用户设置密码
    path: 'groupUserInfo/setPassWord',
    name: 'setGroupPassWord',
    meta: {
      routerType: 2,
    },
    component: () => import('../views/system/user-info/set-pass-word.vue'),
  },
  {
    // 集团用户详情
    path: 'groupUserInfo/staffDetail',
    name: 'groupStaffDetail',
    meta: {
      routerType: 2,
    },
    component: () => import('../views/system/user-info/staff-detail.vue'),
  },
  {
    // 酒店用户设置密码
    path: 'userInfo/setPassWord',
    name: 'setPassWord',
    meta: {
      routerType: 3,
    },
    component: () => import('../views/system/user-info/set-pass-word.vue'),
  },
  {
    // 酒店用户详情
    path: 'userInfo/staffDetail',
    name: 'staffDetail',
    meta: {
      routerType: 3,
    },
    component: () => import('../views/system/user-info/staff-detail.vue'),
  },
];
