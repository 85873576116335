/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 15:37:26
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 15:40:48
 */

import Router from 'vue-router';
import platformRouter from './platformrouter';
import groupRouter from './grouprouter';
import hotelRouter from './hotelrouter';
import systemRouter from './systemrouter';

const RouterView = {
  template: '<div><router-view></router-view></div>',
};

const routes = [{
  path: '/user',
  component: RouterView,
  children: [
    ...platformRouter,
    ...groupRouter,
    ...hotelRouter,
    ...systemRouter,
  ],
}];

export default new Router({
  mode: 'history',
  routes,
});
